.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 2s linear; */
    /* animation-direction: alternate; */
    opacity: 1;
    animation: App-logo-fade infinite 5s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-color: #2d6187;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}

@keyframes App-logo-fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}
